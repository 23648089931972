<template>
   <section class="mb-10 why-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs12 sm10 offset-sm1 class="mt-10">
                        <v-card flat tile color="" width="90%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Secure and Reliable </h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Secure and <br /> Reliable </h3><br />

                                    <p class="solutions-sub-header mt-3">Zamupay, Leading in Payment Infrastructure</p>
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row v-for="(section, index) in sectionInfo" :key="index" :class="{'reverse-div': section.reverse}" class="content-holder ">
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-right  py-0" >
                                       <v-card color="transparent" flat tile height="100%" align="end" class="content-image-holder  py-0" >
                                           <v-card-text align="center" justify="center" class="py-0">
                                           <v-img :src="require(`../../../assets/images/${section.image}`)" class="content-image" :class="section.imgClass"></v-img>
                                           </v-card-text>

                                       </v-card>
                                    </v-col>
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-left" >

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                            <v-col lg="2" md="2" sm="6" xs="12" align="center" justify="center">
                                                <div class="content-number mb-3"> {{ content.number }} </div>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider"></v-divider>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider"></v-divider>
                                            </v-col>
                                            <v-col lg="10" md="10" sm="12" xs="12">
                                               <div class="content-title mb-3"> {{ content.header }} </div>
                                               <div class="content-text" v-html="content.text">
                                               </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Features',
    mixins: [colorMixin, coreMixin],
     computed: {
          sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'yoga.png',
                    imgClass: 'yoga ',
                    content: [
                        {
                            number: '01',
                            header : 'Flexibility of Payments',
                            text: `
                            <p>Start accepting and making payments anywhere, anytime.<p>
                            <p> Zamupay helps solve the inflexibility in payments because of its one-stop platform. Merchants and other corporate organizations are able to enhance their customer experience through Zamupay’s easy sign-up and easy-to-use payments platform. </p>
                            <p>It takes a few minutes from signing up to receiving/making payments.</p>
                            `
                        },
                    ]
                },
                 {
                    section: 6,
                    reverse: false,
                    image: 'secure-payment.png',
                    imgClass: 'secure',
                    content: [
                        {
                            number: '02',
                            header : 'Dynamic',
                            text: `
                            <p>Access to multi-currency, multiple payment and multiple market options: We understand that your businesses must continue operating seamlessly from wherever they are located. With Zamupay, you enjoy seamless multicounty, multi-channel transactions through our multi-currency enabled platform.</p>
                            <p>In addition, Zamupay supports multiple payment options  your customers can pay you through Card, Bank Transfer, USSD, Visa QR, Mobile Money etc.</p>
                            `
                        },
                     
                    ]
                },
                {
                    section: 2,
                    reverse: true,
                    image: 'pay.png',
                    imgClass: 'pay',
                    content: [
                             {
                            number: '03',
                            header : 'One-stop Shop Convenience',
                            text: `
                            <p> A Zamupay customer does not have to access multiple platforms for their payments needs. Once registered, a customer has access to all their payment needs on the go, using one unified platform.</p>
                            `
                        },
                    ]
                },
                {
                    section: 3,
                    reverse: false,
                    image: 'credit-card.png',
                    imgClass: 'credit-card',
                    content: [
                        {
                            number: '04',
                            header : 'Phenomenal Transaction Success Rates',
                            text: `
                            <p> We automatically direct payments through the most optimal channels, supporting the highest transaction success rates and the fastest processing times in the market.</p>
                            `
                        },
                   
                    ]
                },
                  {
                    section: 4,
                    reverse: true,
                    image: 'Zamu_mobile1.png',
                    imgClass: 'secure',
                    content: [
                             {
                            number: '05',
                            header : 'Integration of Payment Experience',
                            text: `
                            <p>The platform has flexible APIs such that Zamupay customers can integrate their business with it to activate payments.</p>
                            <p> In addition, the platform supports manual initiation of payments via a web portal as well as integration with online shopping carts.</p>
                            `
                        },
                    ]
                },
                
                {
                    section: 5,
                    reverse: false,
                    image: 'credit-card.png',
                    imgClass: 'credit-card',
                    content: [
                        {
                            number: '06',
                            header : 'Advanced Fraud Detection',
                            text: `
                            <p> 
                            The Zamupay platform has a combination of automated fraud system monitoring tools that proactively flag high-risk activity, detect illegitimate transactional behavior online and provides alerts and analysis tools for your relevant personnel.</p>
                            `
                        },
                    ]
                },
               
                  {
                    section: 7,
                    reverse: true,
                    image: 'fintech1.png',
                    imgClass: 'pay',
                    content: [
                        {
                            number: '07',
                            header : 'Virtual Terminal',
                            text: `
                            <p>Zamupay has a secure web link that supports payment tokenization in addition to allowing customers to process card payments online.</p>
                            `
                        },
                    ]
                }
            ];
            return data;
        },
     },

}
</script>

<style scoped>
.why-holder {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/rabbitWatermark.png') !important;
    background-position: left bottom 10px !important;
    background-size: 95%;

}
 .content-number {
     color: #d29f2a;
    font-size: 28px;
    font-weight: 700;
    line-height: 29.55px;
 }
 .content-title {
     color: #43237d;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
 }
 .content-text {
    color: #727272;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
 }
 .content-image-holder {
    position: relative;

 }
.content-image {
    margin-bottom: 6em;

}
.content-holder {
    margin-bottom: 24px;
}
.credit-card {
       width: 55%;
    margin-top: -5em;
}
.yoga {
    width: 38%;
}
.secure {
    width: 100%;
    margin-top: -4em;
}
.secure-payment {
    margin-bottom: 0em;
    margin-top: 12em;
}
.solutions-sub-header {
    text-align: center;
    width: 100%;
}
.pay {
    width: 69%;
}
</style>

